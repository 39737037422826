<template>
  <div style="height: 100vh;">
    <router-view></router-view>
    <div style="position: absolute;bottom: 0;width: 100%">
      <div class="container">
        <div class="tabs">
          <div @click="setRouter('/workspaceMini/approval','approval')">
            <el-image v-if="title == 'approval'" :src="require('/src/assets/image/home.png')" style="width: 22px; height: 22px"/>
            <el-image v-else :src="require('/src/assets/image/homes.png')" style="width: 22px; height: 22px"/>
            <div :style="title == 'approval' ? 'color: #2a99ff;':''">申请</div>
          </div>
          <div @click="setRouter('/workspaceMini/approver','approver')">
            <el-image v-if="title == 'approver'" :src="require('/src/assets/image/audit.png')" style="width: 22px; height: 22px"/>
            <el-image v-else :src="require('/src/assets/image/audits.png')" style="width: 22px; height: 22px"/>
            <div :style="title == 'approver' ? 'color: #2a99ff;':''">审批</div>
          </div>
          <div @click="setRouter('/workspaceMini/my','my')">
            <el-image v-if="title == 'my'" :src="require('/src/assets/image/my.png')" style="width: 22px; height: 22px"/>
            <el-image v-else :src="require('/src/assets/image/mys.png')" style="width: 22px; height: 22px"/>
            <div :style="title == 'my' ? 'color: #2a99ff;':''">我的</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      title:"approval"
    }
  },
  created() {
    let tabBar = this.$store.tabBar;
    if (tabBar){
      this.title = tabBar;
    }
  },
  methods: {
    setRouter(url,type) {
      if (url != this.$route.path){
        this.$router.replace({path: url})
        this.title = type;
        this.$store.tabBar = type;
      }
    },
  }
}
</script>

<style scoped>
.tabs {
  color: #606266;
  font-size: 12px;
  display: flex;
  position: relative;
  justify-content: space-around;
  padding: 18px 0 26px 0;
  background-color: #fff;
  text-align: center;
  border-top: #F0F2F5 solid 1px;
}
</style>